import React from 'react';
import { graphql } from 'gatsby';
import { useI18next } from '../../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import { container, header, icon, title, text, layout } from './expired.module.scss';
import WarningIcon from '../../../assets/images/svg/warning.svg';
import { IPage } from '../../../models/page.model';

import GrajWKlasykeLayout from '../../../layouts/graj-w-klasyke-layout';

interface IGrajWKlasykeExpiredPageProps {
    readonly data: {
        page: IPage | null;
    };
}

const Expired: React.FC<IGrajWKlasykeExpiredPageProps> = ({ data }) => {
    const { t } = useI18next();
    const { page } = data;

    return (
        <GrajWKlasykeLayout page={page} className={container} layoutClass={layout}>
            <div className={header}>
                <WarningIcon className={icon} />
                <h1 className={title}>{t('graj.w.klasyke.expired.title')}</h1>
                <p className={text}>{t('graj.w.klasyke.expired.description')}</p>
            </div>
        </GrajWKlasykeLayout>
    );
};

export const query = graphql`
    query ($language: String!, $site: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }

        page: strapiPage(
            locale: { eq: $language }
            site: { eq: $site }
            isDummyContent: { eq: false }
        ) {
            ...pageFields
        }
    }
`;

export default Expired;
